import { useEffect, useState } from "react";
import './FullAutoStep3.css';
import { CheckCircle } from "@mui/icons-material";
import ReactSwitch from "react-switch";


function FullAutoStep3({selectedStep, suggestedOffers, selectedOffers, setSelectedOffers, multipleOffersPerAccount, setMultipleOffersPerAccount, maxRecipientsPerCreative, setMaxRecipientsPerCreative}) {

    const [suggestedOffersSearchText, setSuggestedOffersSearchText] = useState('');
    const [filteredSuggestedOffers, setFilteredSuggestedOffers] = useState([]);

    
    useEffect(() => {
        setFilteredSuggestedOffers(suggestedOffers)
        setSuggestedOffersSearchText('')
    }, [suggestedOffers])


    useEffect(() => {
        if (suggestedOffersSearchText !== '') {
          const filteredSuggestedOffers = suggestedOffers.filter(x => x.prompt_name.toLowerCase().includes(suggestedOffersSearchText.toLowerCase()))
          setFilteredSuggestedOffers(filteredSuggestedOffers)
        } else {
            setFilteredSuggestedOffers(suggestedOffers)
        }
    }, [suggestedOffersSearchText])



    async function adjustSelection(offer) {
        let updatedOffers = [];
        
        // Check if the offer is already selected
        const isSelected = selectedOffers.some(item => item.prompt_name === offer.prompt_name);
    
        if (isSelected) {
            // If the offer is already selected, remove it
            updatedOffers = selectedOffers.filter(item => item.prompt_name !== offer.prompt_name);
        } else {
            // If the offer is not selected, add it
            updatedOffers = [...selectedOffers, offer];
        }
    
        setSelectedOffers(updatedOffers);
    }

    console.log(selectedOffers)
    

    function formatNumber(number) {
        if (number >= 1e9) {
          return (number / 1e9).toFixed(1) + 'B';
        } else if (number >= 1e6) {
          return (number / 1e6).toFixed(1) + 'M';
        } else if (number >= 1e3) {
          return (number / 1e3).toFixed(1) + 'K';
        } else {
          return number.toString();
        }
      }
      


  return (
    <div className={selectedStep == 3 ? "FullAutoStep3Container" : "FullAutoStep3ContainerHidden"}>
        <div className="FullAutoStep3Left">
            <span className="FullAutoStep3LeftStepNumber">Step 3</span>
            <span className="FullAutoStep3LeftTitle">Select Offers</span>
            <div className="FullAutoStep3LeftDescription">
                <ReactSwitch
                  onChange={(checked) => setMultipleOffersPerAccount(checked)}
                  checked={multipleOffersPerAccount}
                  offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                />
                <span className='FullAutoStep3LeftDescriptionText'>Multiple offers per account</span>
            </div>
        </div>
        <div className="FullAutoStep3Middle">
            {/* <span className="FullAutoStep3MiddleTitle">Select a mailing list</span>
            <span className="FullAutoStep3MiddleSubTitle">Select the mailing list you want to send your campaign to.</span> */}
            <div className="FullAutoStep3MiddleAccountContainer">
                <input className="FullAutoStep3MiddleAccountSearch" type="text" placeholder="Search for an offer" onChange={(e) => {setSuggestedOffersSearchText(e.target.value)}} value={suggestedOffersSearchText} />
                {filteredSuggestedOffers.map((x) => (
                    <div className="FullAutoStep3MiddleAccount" onClick={() => {adjustSelection(x);}} key={x.prompt_name}>
                        <div className="FullAutoStep3MiddleAccountLeft">
                            <span className="FullAutoStep3MiddleAccountName">{x.prompt_name}</span>
                            <span className="FullAutoStep3MiddleAccountQuota">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.total_payout)} Earnings &bull; {formatNumber(x.total_sent)} Sent </span>
                        </div>
                        <div className="FullAutoStep3MiddleAccountRight">
                            <span className="FullAutoStep3MiddleAccountRightText">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.epm)}</span>
                            <span className="" style={{fontSize: 8}}>EPM</span>
                        </div>
                        <div className={selectedOffers?.some(selected => selected.prompt_name === x.prompt_name) ? "FullAutoStep3MiddleAccountSelectedCheck" : "FullAutoStep3MiddleAccountHiddenCheck"}>
                            <CheckCircle style={{color: '#1dca23', fontSize: 30}}/>
                        </div>
                        {selectedOffers?.some(selected => selected.prompt_name === x.prompt_name) && !multipleOffersPerAccount && (
                        <div className="FullAutoStep3MiddleAccountBottomProgressParent">
                            <div className="FullAutoStep3MiddleAccountBottomProgressChild">

                            </div>
                        </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
        <div className="FullAutoStep3Right">

        </div>
    </div>
  );
}


export default FullAutoStep3;